import { useCart } from "contexts/cart-context";
import { useEffect, useState } from "react";
import formatPrice from "utils/formatPrice";
import CartProducts from "./CartProducts";
import useCartProducts from "contexts/cart-context/useCartProducts";
import * as S from "./style";
import "./Cart.css";
import swal from "sweetalert";
import VaiuCheckout from 'components/Vaiu/VaiuCheckout';
import {VaiuResult, VaiuError} from 'components/Vaiu/VaiuCheckout';
import { globalShopName } from "components/App/globals";

// set the demo value to the demo query parameter from the URL
var demo = 1;


const urlParams = new URLSearchParams(window.location.search);
if (urlParams.has('demo')) {
  demo = parseInt(urlParams.get('demo') || '1');
}


const Cart = () => {
  const { products, total, isOpen, openCart, closeCart } = useCart();
  const { removeProducts } = useCartProducts();
  const [showVaiu, setShowVaiu] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [discount, setDiscount] = useState(0);
  const [cartData, setCartData] = useState({total, products, remainderToPay: total.totalPrice});
 
  useEffect(() => {
    setPaid(false);
    if ( demo === 1 ) {
      setShowVaiu(false);
    }
    else {
      setShowVaiu(true);
    }

  }, [demo]);

  useEffect(() => {
    setCartData({total, products, remainderToPay: total.totalPrice-discount});
  }, [total, products]);

  const handleCheckoutWithVaiu = () => {
    setShowVaiu(true);
  };

  const handleVaiu = (result:VaiuResult,error:VaiuError) => {
    if ( error ) {
      handleVaiuError(error);
    }
    else {
      const amount = result.amount;
      const { totalPrice } = total;
      const totalDiscount = discount + amount;
      setDiscount(totalDiscount);
      //Check total price with totalDiscount

      if (totalPrice - totalDiscount > 0) {
        cartData.remainderToPay = totalPrice - totalDiscount;
        if (cartData.remainderToPay < 0) {
          cartData.remainderToPay = 0;
        }
        setCartData(cartData);
      
      
        return showPayment(amount);
      }
      if (totalPrice - totalDiscount <= 0) {
        setShowVaiu(false);
        return showSuccess();
      }
    }
  }
  
  const handleVaiuError = (error: VaiuError) => {
    return showError(error.message);
  }

  const showAlert = (alert: string): void => {
    swal({
      title: alert,
      icon: "info",
    });
  };

  const showError = (errorMessage: string): void => {
    swal({
      title: "We are sorry",
      text: errorMessage,
      icon: "error",
    }).then(() => {
      setShowVaiu(false);
    });
  };

  const showPayment = (amount: number): void => {
    swal({
      title: "Payment successful",
      text: `The code entered has a value of ${total.currencyFormat}${amount}, enter another code to complete the purchase.`,
      icon: "info",
    }).then(() => {
      setShowVaiu(false);
    });
  };

  const showSuccess = (): void => {
    setPaid(true);
    swal({
      title: "Success",
      text: `Payment successfully completed`,
      icon: "success",
    }).then(() => {
      closeCart();
      setPaid(false);
      setDiscount(0);
      removeProducts();
    });
  };

  const handleToggleCart = (isOpen: boolean) => () => {
    isOpen ? closeCart() : openCart();
  };


  return (
    <S.Container isOpen={isOpen}>
      <S.CartButton onClick={handleToggleCart(isOpen)}>
        {isOpen ? (
          <span>X</span>
        ) : (
          <S.CartIcon>
            <S.CartQuantity title="Products in cart quantity">
              {total.productQuantity}
            </S.CartQuantity>
          </S.CartIcon>
        )}
      </S.CartButton>

      {isOpen && (
        <S.CartContent>
          <S.CartContentHeader>
            <S.CartIcon large>
              <S.CartQuantity>{total.productQuantity}</S.CartQuantity>
            </S.CartIcon>
            <S.HeaderTitle>Cart</S.HeaderTitle>
          </S.CartContentHeader>

          <CartProducts products={products}/>

          <S.CartFooter>
            <S.Sub>SUBTOTAL</S.Sub>
            <S.SubPrice>
              <S.SubPriceValue>{`${total.currencyFormat} ${formatPrice(
                total.totalPrice - discount,
                total.currencyId
              )}`}</S.SubPriceValue>
              <S.SubPriceInstallment>
                {total.installments ? (
                  <span>
                    {`OR UP TO ${total.installments} x ${
                      total.currencyFormat
                    } ${formatPrice(
                      total.totalPrice / total.installments,
                      total.currencyId
                    )}`}
                  </span>
                ) : null}
              </S.SubPriceInstallment>
            </S.SubPrice>

            {showVaiu && !paid && (
              <S.VaiuCheckoutContainer>
                <VaiuCheckout amount={cartData.remainderToPay} onResult={handleVaiu} demo={demo} />
              </S.VaiuCheckoutContainer>
            )}
            {!showVaiu && !paid && (
              <S.CheckoutButton onClick={handleCheckoutWithVaiu} autoFocus>
                Pay <S.SubPriceValue>{`${total.currencyFormat} ${formatPrice(
                total.totalPrice - discount,
                total.currencyId
              )}`}</S.SubPriceValue> with Vaiu
                <br/>
              </S.CheckoutButton>
            )}

            {paid && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  flexDirection: "column",
                }}
              >
                <div className="SucessContainer">
                  <div className="w3-modal-icon w3-modal-success animate">
                    <span className="w3-modal-line w3-modal-tip animateSuccessTip"></span>
                    <span className="w3-modal-line w3-modal-long animateSuccessLong"></span>
                    <div className="w3-modal-placeholder"></div>
                    <div className="w3-modal-fix"></div>
                  </div>
                </div>
                <p style={{ color: "white", fontWeight: "bold" }}>Successful</p>
              </div>
            )}
          </S.CartFooter>
        </S.CartContent>
      )}
    </S.Container>
  );
};

export default Cart;
