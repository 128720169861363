import axios from "axios";
import { IOrderData, IPayment, IPostPaymentResponse } from "models";

//const URL_PAY = "http://localhost:8001/api/payment"
const URL_PAY = "https://checkout-demo-merchant-server.vaiu.io/api/payment"
const CODE = "Gjirafa"
export const payment = async (pinCode: string, orderData: IOrderData): Promise<IPayment> => {
  const token = sessionStorage.getItem("token") ?? null;
  console.log("orderData", orderData);
  
  const orderDataString = JSON.stringify(orderData);
  console.log("got orderdata: ", orderDataString);

  return await axios.post(
    URL_PAY,
    { pin: pinCode, code: CODE, token: token, message: orderDataString },
    { headers: { 'Content-Type': 'application/json' } }
  ).then((responsePayment: IPostPaymentResponse) => {
    const set_token = responsePayment.data.token
    sessionStorage.setItem("token", set_token);
    const pay: IPayment = responsePayment.data;
    console.log("payment: ", { ...pay });
    return pay;
  }).catch((e) => {
    const error: IPayment = {
      amount: 0,
      token: "",
      errorMessage: "The code you provided is incorrect."
    }
    return error
  });
};
