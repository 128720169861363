import React from 'react';

const VaiuIFrame = ({ src, amount, title="", scale = 1 }) => {
  const iframeStyle = {
    width: `${100 / scale}%`,   // Adjust width to account for scaling
    height: `${100 / scale}%`,  // Adjust height to account for scaling
    transform: `scale(${scale})`, // Scale down or up the iframe content
    transformOrigin: '0 0',  // Ensures the scaling starts from top left corner
    border: 'none'
  };

  const containerStyle = {
    width: '100%',
    height: '300px',
    overflow: 'hidden'  // Hides any overflow caused by scaling
  };

  return (
    <div style={containerStyle}>
      <iframe src={src} style={iframeStyle} title={title}></iframe>
    </div>
  );
};

export default VaiuIFrame;
