import { useEffect } from "react";

import { useProducts } from "contexts/products-context";
import { globalShopName, setGlobalShopName } from "components/App/globals";

import Loader from "components/Loader";
import Filter from "components/Filter";
import Products from "components/Products";
import Cart from "components/Cart";
import Logo from "../../static/logo-01.png";
import * as S from "./style";

const urlParams = new URLSearchParams(window.location.search);

const shop = urlParams.get('shop') || 'clothing'; // Provide a default empty string if null
if (shop) {
  // set the global shop name to the shop query parameter from the URL
  setGlobalShopName(shop);
}

const demo = urlParams.get('demo') || '1'; // Provide a default empty string if null


function App() {
  const { isFetching, products, fetchProducts } = useProducts();


  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <S.Container>
      <a href={`/?demo=1&shop=${globalShopName}`}>Demo 1-click</a> &nbsp; |
      <a href={`/?demo=2&shop=${globalShopName}`}>Demo Enter Code</a> &nbsp; |
      <a href={`/?demo=3&shop=${globalShopName}`}>Demo Scan QR</a> &nbsp; |
      <a href={`/?demo=4&shop=${globalShopName}`}>Demo Embedded App</a> &nbsp; |
      <a href="/?shop=groceries">Go to Groceries Shop</a>
      &nbsp; |
      <a href="/?shop=clothing">Go to Clothing Shop</a>
<br/>
<h4>Demo: {demo}, Shop: {globalShopName}</h4>

      {isFetching && <Loader />}
      <S.TwoColumnGrid>
        <S.Side>
          <img style={{ width: "100px", marginBottom: "20px" }} src={Logo} />
          <Filter />
        </S.Side>
        <S.Main>
          <S.MainHeader>
            <p>{products?.length} Product(s) found</p>
          </S.MainHeader>
          <Products products={products} />
        </S.Main>
      </S.TwoColumnGrid>
      <Cart />
    </S.Container>
  );
}

export default App;
