import React, { useState, useEffect } from 'react';
import PinInput from 'react-pin-input';

const VaiuCodeInput = ({ onCompleted }) => {
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (pin.length === 6) {
      onCompleted(pin);
    }
  }, [pin, onCompleted]);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData && pastedData.length === 6) {
      setPin(pastedData);
    }
  };

  return (
    <div onPaste={handlePaste}>

          <PinInput
              length={6}
              focus
              // disabled
              // secret
              // ref={(p) => (this.pin = p)}
              type="custom"
              onChange={(value, index) => {
                setPin(value);
              }}
              inputStyle={{ width: "35px" }}
              style={{ color: "white", marginTop: "20px" }}
          />
            </div>
  );
};

export default VaiuCodeInput;