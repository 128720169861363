import React, { useState, useEffect } from 'react';
import {QrScanner} from "react-qrcode-scanner";
import styled from 'styled-components';

  
const OverlayDiv = styled.div`
  position: absolute; /* Positioned absolutely to float over the scanner */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Offset the position by half its size to truly center */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* Text color */
  padding: 10px; /* Padding around the text */
  border-radius: 8px; /* Optional: rounded corners */
  font-size: 1.2em; /* Text size */
  z-index: 10; /* Ensure it's above the scanner */
`;

const QRCodeScanner = ({ onScanComplete }) => {
    const [data, setData] = useState('Scan Vaiu Code');
    const [showScanner, setShowScanner] = useState(true);  // State to control the visibility

    useEffect(() => {
        // Call the onScanComplete function when the data is updated
        if (!showScanner && data && onScanComplete) {
            onScanComplete(data);
        }
    });

    const handleScan = (result) => {
        console.log(result)
        setData(result); // Assuming result object contains a text field with the QR code data
        setShowScanner(false);  // Hide scanner once QR code is scanned
    }

    const handleError = (error) => {
        console.log({error})
    }

    return (
        <>
        { showScanner && (
            <div>
                <QrScanner style={{ width: '150px' }}
                    onScan={handleScan}
                    onError={handleError}
                    aspectRatio='4:3'
                    viewFinder={{ //any valid JS-CSS can be added here
                        border: '12px solid rgba(255,255,255,0.3)',
                        position: 'absolute',
                        borderRadius: '5px',
                        width: '100px',
                        height: '75px'
                    }}
                    resolution={200} //canvas resolution

                /** Default props

                 onError = (error) => console.log({error}),
                 
                 onScan = (value) => console.log({value}),
                 
                 facingMode = 'environment', // environment|face
                 
                 constraints = null, //device constraints
                 
                 onLoad = (val :{mirrorVideo, streamLabel}) => null,
                 
                 flipHorizontally = false, //flip or reflect the video output based on facing mode
                 
                 style, //section styling can be added here
                 
                 className, //classnames will be added to the section wrapper
                 
                 delay = 800, //delay between each scan
                 
                 
                 aspectRatio = '16:9',
                       
                 showViewFinder = true,
                 
                 */
                />
                    {data && (
                        <OverlayDiv>
                            {data}
                        </OverlayDiv>
                    )}
            </div>
        )}
    </>
    );
}

export default QRCodeScanner;