import styled from 'styled-components';


export const CheckoutButton = styled.button`
  width: 100%;
  border: 0;
  color: #ececec;
  text-transform: uppercase;
  background-color: #0c0b10;
  text-align: center;
  padding: 15px 0;
  margin-top: 10px;
  cursor: pointer;
  outline: none;

  transition: background-color 0.2s;

  &:focus-visible {
    outline: 3px solid ${({ theme }) => theme.colors.secondary};
  }

  &:hover {
    background-color: #000;
  }
`;

export const Pins = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: '2px';
`;


export const QRScanner = styled.div`
position: relative; /* Relative position to serve as anchor for absolute elements inside */
width: 200px; /* Fixed width for the QR scanner */
margin: auto; /* Centers the QR scanner horizontally */
padding: 10px;
display: flex; /* Optional: Aligns child content if needed */
align-items: center; /* Centers content vertically */
justify-content: center; /* Centers content horizontally */
`;

export const VaiuCheckoutContainer = styled.div`
  display: flex; /* Enables flexbox layout */
  max-width: 200px; /* Sets a fixed width for the entire container */
  max-height: 250px; /* Sets a fixed height for the entire container */
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const VaiuIFrameContainer = styled.div`
  max-width: 300px; /* Sets a fixed width for the entire container */
  max-height: 250px; /* Sets a fixed height for the entire container */
  margin: auto; /* Centers the container horizontally */
  border: 4px; /* Optional: Removes the border of the iframe */
  overflow-y: auto; /* Enables vertical scrolling if needed */
`; 
