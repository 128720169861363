import { IGetProductsResponse } from 'models';
import { globalShopName } from "components/App/globals";

const isProduction = process.env.NODE_ENV === 'production';

export const getProducts = async () => {
  let response: IGetProductsResponse;

  console.log('globalShopName', globalShopName);
  response = require(`static/json/${globalShopName}/products.json`);
  console.log('response', response);
  const { products } = response.data || [];

  return products;
};
