import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeDisplay = ({ value, size = 80 }) => {
  return (
    <div style={{ margin: '10px', color:"white",
    fontSize: "0.5em",
    display: "block" }}>
      <span><a href={value} target="vaiuapp" style={{color:"white"}}>Make a Vaiu Code</a></span>
<br/>
      <QRCode value={value} size={size} />
    </div>
  );
};

export default QRCodeDisplay;
